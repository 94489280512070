import React, { useRef, useState } from 'react';

import CountUp from 'react-countup';
import SectionTitle from '../shared/section-titles';
import VisibilitySensor from 'react-visibility-sensor';

const VideoSection = ({ title, text }) => {
	const startRef = useRef(null);
	const [clients, setClients] = useState(0);
	const [coverage, setCoverage] = useState(0);
	const [speed, setSpeed] = useState(0);
	const [homes, setHomes] = useState(0);
	const duration = 5;

	return (
		<>
			<section id="video" className="spacing-bottom center">
				<div className="my-5">
					<SectionTitle undertext={text}>
						<h2 dangerouslySetInnerHTML={{ __html: title }}></h2>
						<hr className="center" />
					</SectionTitle>

					<VisibilitySensor
						onChange={(isVisible) => {
							if (isVisible) {
								setClients(23000);
								setCoverage(40);
								setSpeed(500);
								setHomes(25);
							}
						}}
						delayedCall
					>
						<div className="container mt-5">
							<hr className="normal" />
							<div className="text-center row d-flex justify-content-between">
								<div className="col-6 col-md-3">
									<h2 className="statistics">
										<CountUp
											start={0}
											end={clients}
											duration={duration}
											ref={startRef}
										/>
										+
									</h2>
									<h3>Приятели (клиенти)</h3>
								</div>
								<div className="col-6 col-md-3">
									<h2 className="statistics">
										<CountUp
											start={0}
											end={coverage}
											duration={duration}
										/>
									</h2>
									<h3>Населени места</h3>
								</div>
								<div className="col-6 col-md-3">
									<h2 className="statistics">
										<CountUp
											start={0}
											end={speed}
											duration={duration}
										/>
										+ км
									</h2>
									<h3>Оптична мрежа</h3>
								</div>
								<div className="col-6 col-md-3">
									<h2 className="statistics">
										<CountUp
											start={0}
											end={homes}
											duration={duration}
										/>
									</h2>
									<h3>Години в бранша</h3>
								</div>
							</div>
							<hr className="normal" />
						</div>
					</VisibilitySensor>
				</div>
			</section>
		</>
	);
};

export default VideoSection;

