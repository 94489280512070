import React, { useMemo, useRef, useState } from 'react';

import SectionTitles from '../../shared/section-titles';
import Slider from 'react-slick';
import classnames from 'classnames';
import styles from './client-testimonials.module.css';

const ClientTestimonials = ({ sectionTitle, opinions }) => {
	const slider = useRef(null);
	const [activeSlider, setActiveSlider] = useState(0);

	const dots = useMemo(() => {
		const dots = [];
		for (let i = 0; i < opinions.length; i++) {
			dots.push(i);
		}
		return dots;
	}, [activeSlider, opinions]);

	return (
		<>
			<section id="client-testimonials" className={styles.testimonialsSection}>
				<div className={classnames('container', styles.sliderWrapper)}>
					<div className="row d-flex justify-content-center">
						<div className="col-12">
							<SectionTitles>
								{sectionTitle}
								<hr className={'center'} />
							</SectionTitles>
						</div>

						<div className={classnames('col-12 mx-5 reasons-slider')}>
							<Slider
								ref={slider}
								{...settings}
								afterChange={(i) => setActiveSlider(i)}
							>
								{opinions.map(({ node }, index) => (
									<div>
										<div
											className={styles.testimonialWrapper}
											key={index}
										>
											<h6
												className={styles.testimonialTitle}
												dangerouslySetInnerHTML={{
													__html: node.title
												}}
											/>
											<span className={styles.testimonialRating}>
												<span
													className={styles.testimonialStar}
												/>
												<span
													className={styles.testimonialStar}
												/>
												<span
													className={styles.testimonialStar}
												/>
												<span
													className={styles.testimonialStar}
												/>
												<span
													className={styles.testimonialStar}
												/>
											</span>
											<div
												className={styles.testimonialContent}
												dangerouslySetInnerHTML={{
													__html: node.content
												}}
											/>
											<p className={styles.testimonialMeta}>
												{node.acf.location}{' '}
												{node.acf.date && (
													<>
														{' | '}
														<i>{node.acf.date}</i>
													</>
												)}
											</p>
										</div>
									</div>
								))}
							</Slider>
							<ul className="slick-dots d-block">
								{dots.map((i) => (
									<li
										id={i}
										className={classnames('', {
											'slick-active': activeSlider === i
										})}
										onClick={(e) =>
											slider?.current?.slickGoTo(
												Number(e.currentTarget.id)
											)
										}
									>
										<button>{i}</button>
									</li>
								))}
							</ul>
							<span
								className={styles.prevArrow}
								onClick={() => slider?.current?.slickPrev()}
							/>
							<span
								className={styles.nextArrow}
								onClick={() => slider?.current?.slickNext()}
							/>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

const settings = {
	arrows: false,
	dots: false,
	speed: 1000,
	autoplaySpeed: 3000,
	infinite: true,
	autoplay: false,
	focusOnSelect: true,
	pauseOnFocus: true,
	slidesToScroll: 1,
	slidesToShow: 3,
	centerMode: true,
	// centerPadding: '-10px',
	responsive: [
		{
			breakpoint: 1100,
			settings: {
				slidesToShow: 2
			}
		},
		{
			breakpoint: 800,
			settings: {
				slidesToShow: 1.65,
				arrows: false,
				centerPadding: '70px'
			}
		},
		{
			breakpoint: 500,
			settings: {
				slidesToShow: 1,
				arrows: false,
				centerPadding: '70px'
			}
		},
		{
			breakpoint: 450,
			settings: {
				slidesToShow: 1,
				arrows: false,
				centerPadding: '40px'
			}
		},
		{
			breakpoint: 400,
			settings: {
				slidesToShow: 1,
				arrows: false,
				centerPadding: '20px'
			}
		},
		{
			breakpoint: 350,
			settings: {
				slidesToShow: 1,
				arrows: false,
				centerPadding: '0px'
			}
		}
	]
};

export default ClientTestimonials;

