import './usp-hero.css';

import InfoIconPopup from '../../shared/info-icon-popup/info-icon-popup';
import React from 'react';
import Slider from 'react-slick';
import peacemaker from '../../../images/index/peacemaker-badge.png';
import usp1 from '../../../images/index/usp-1.svg';
import usp2 from '../../../images/index/usp-2.svg';
import usp3 from '../../../images/index/usp-3.svg';
import usp4 from '../../../images/index/usp-4.svg';

const USPs = [
	{
		icon: peacemaker,
		title: <>Сигурност с Peacemaker от Whalebone</>,
		popupText: 'Благодарение на DNS защитата от Whalebone, ние сме в готовност да отблъснем всякакъв вид атаки, на които дори и най-добрите антивирусни програми и защитни стени не могат да противодействат.'
	},
	{
		icon: usp2,
		title: (
			<>
				Нямаш ток,
				<br /> имаш нет
			</>
		),
		popupText:
			'GPON Интернет без прекъсване, посредством батерия за рутера, с която ще имаш Wi-Fi, дори когато няма ток.'
	},
	{
		icon: usp3,
		title: (
			<>
				Безкабелна
				<br />
				Интерактивна ТВ
			</>
		),
		popupText: 'Благодарение на нашето TV приложение, приемникът става излишен.'
	},
	{
		icon: usp4,
		title: (
			<>
				Обслужване
				<br />
				24/7
			</>
		),
		popupText: 'Преди да си помислиш и сме на вратата ти.'
	}
];

const UspCard = ({ icon, title, popupText }) => {
	return (
		<div className="d-flex d-xl-block justify-content-center usp-wrapper">
			<div className="d-flex justify-content-start align-items-center my-3 pr-xl-2">
				<img src={icon} className="img-fluid mr-2 usp-icon" />
				<h3 className="usp-hero-title m-0">{title}</h3>
				<div className="h-100 d-flex justify-content-end align-items-end">
					<InfoIconPopup
						popupText={popupText}
						className="ml-2 mb-3 mb-md-0"
					/>
				</div>
			</div>
		</div>
	);
};

const sliderSettings = {
	slidesToShow: 4,
	centerMode: false,
	infinite: true,
	autoplay: true,
	autoplaySpeed: 3000,
	responsive: [
		{
			breakpoint: 1200,
			settings: {
				slidesToShow: 2
			}
		},
		{
			breakpoint: 991,
			settings: {
				slidesToShow: 2
			}
		},
		{
			breakpoint: 767,
			settings: {
				slidesToShow: 1,
				centerPadding: '0px',
				centerMode: true
			}
		}
	]
};

const UspHero = () => {
	return (
		<>
			<section>
				<div className="container px-lg-0">
					<div className="py-2">
						<Slider {...sliderSettings}>
							{USPs.map((usp, index) => (
								<UspCard {...usp} key={index} />
							))}
						</Slider>
					</div>
				</div>
			</section>
		</>
	);
};

export default UspHero;

