import './hero.css';

import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

const HeroWrapper = styled.div`
	padding-top: 10rem;

	@media screen and (max-width: 720px) {
		padding-top: 8.5rem;
	}
	@media screen and (max-width: 576px) {
		padding-top: 7rem;
	}
`;

const Hero = () => {
	return (
		<>
			<section id="hero">
				<Link to="/svoboda/">
					<div className="container">
						<HeroWrapper className="row pb-5 position-relative">
							<div className="col-7 hero-text p-fixed">
								{/* <div className="hero-btn">
									<Link
										to={'/svoboda/'}
										className="btn btn-primary header-button"
										id="pcta-header"
										style={{ color: 'white' }}
									>
										Виж повече
									</Link>
								</div> */}
							</div>
						</HeroWrapper>
					</div>
				</Link>
			</section>
		</>
	);
};

export default Hero;

